import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import "prismjs/themes/prism-tomorrow.css"
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { Link, withPrefix } from 'gatsby'
import logo from '../img/logo_transparent.png'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/logo-h.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/logo-h.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/logo-h.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/logo-h`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={'https://www.hacknotes.jp/img/logo.png'}
        />
        <meta property="og:description" content={description} />
      </Helmet>
      <Link to="/" title="Logo">
        <img src={logo} className="header-logo" alt="logo" />
      </Link>
      <div className="main-contents">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
